@import (reference) "styles/theme.less";

.subHeaderFilledContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 3px;
  padding: 20px;
  width: 100%;
  background: var(--white);
  border-radius: 12px 12px 0 0;

  .title {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    display: flex;
    align-items: center;
  }

  .sideText {
    color: var(--queen-blue);
    font-weight: 500;
  }
}

.tooltipIcon {
  margin-left: 6px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  color: var(--graham-grey);
  border: 1px solid var(--graham-grey);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltipContent {
  white-space: pre-line;
}

.subTitle {
  font-family: "Barlow";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: var(--queen-blue) !important;
  margin-top: 3px;
  width: 100%;
  text-align: justify;
}
.subHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.subHeaderFilledGradientContainer {
  .subHeaderFilledContainer();
  background: linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;